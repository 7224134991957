// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  userLanguageStorageKey: "SAIDA_language",
  apiBaseUrl: "https://api-cropcalendar.review.fao.org/api/v1/",
  sync_url:
    "https://fao-digital-services-portfolio.herokuapp.com/synchronisation/",
  meteo: "https://api-digital.review.fao.org/api/v1/meteo/",
  WAPOR_CATALOG_API: "https://io.apps.fao.org/gismgr/api/v1/catalog",
  WAPOR_QUERY_API: "https://io.apps.fao.org/gismgr/api/v1/query",
  strTextToSpeechUrl:
    "https://api-digital.review.fao.org/api/v1/textToSpeech?text=",
  firebaseCommImg:
    "https://firebasestorage.googleapis.com/v0/b/fao-digital-services-portfolio.appspot.com/o/img%2Ffpma%2F",
  config: {
    useEmulators: false,
    apiKey: "AIzaSyAg-YFjrlc1FH-4nnGw2FFaf526cSBoME4",
    authDomain: "fao-portfolio-dev.firebaseapp.com",
    databaseURL: "https://fao-portfolio-dev.firebaseio.com",
    projectId: "fao-portfolio-dev",
    storageBucket: "fao-portfolio-dev.appspot.com",
    messagingSenderId: "124577118113",
    appId: "1:124577118113:web:dcbaa7701a3994c2287de0",
  },
  geoLocationIp: "https://geolocation-db.com/json/",
  geoLocationApiKey: "AIzaSyCa_nfFi-KBZtfRwtCsRCfJm_u6xXzwZbU",
  geoLocationApiEndpoint: "https://api-digital.review.fao.org/api/v1/geoInfo",
  googleAnalyticsId: "G-W8RK6NRNTX",
  weatherDetail:
    "https://api-digital.review.fao.org/api/v1/meteo/forecasts/timeseries?lat=latitude&long=longitude&start_date=startDate&end_date=endDate",
  cropImageStorage: "fao-cropcalendar-review.appspot.com",
  feedbackApi: "https://api.data.review.fao.org/api/v1/map/feedback",
  bigQueryApi:
    "https://data.apps.fao.org/api/v2/bigquery",
  fpmaPriceSeries:
    "https://fpma.review.fao.org/giews/v4/price_module/api/v1/FpmaSeriePrice/{uuid}/?periodicity=monthly",
  bigTableName: "fao_dsp_agrimarket_review",
  district_Details:
    "https://api-digital.review.fao.org/api/v1/districts/{code_3}?lat={lat}&long={long}",
  fpmaMarketDetails:
    "https://fpma.review.fao.org/giews/v4/price_module/api/v1/Market/",
  cloudApiUrl:
    "https://us-central1-fao-portfolio-dev.cloudfunctions.net/api/v1/",
  feedCalculator:
    "https://api-digital.review.fao.org/api/v1/animal_feed/{dimention}/{animal}/calculate",
  chatBaseUrl: "https://api-digital.review.fao.org/api/v1/",
  recognizeInsectUrl:
    "https://api-digital.apps.fao.org/api/v1/insects/recognition",
  pandemicReportUrl:
    "https://api-digital.review.fao.org/api/v1/reports/{country}",
  animal_feed: {
    openai:
      "https://dsp-ai-service-dot-fao-portfolio-dev.appspot.com/api/openai/animal_feed/best_recipe",
    llama:
      "https://dsp-ai-service-dot-fao-portfolio-dev.appspot.com/api/llama/animal_feed/best_recipe",
    algorithm:
      "https://dsp-standard-service-dot-fao-portfolio-dev.appspot.com/api/animal_feed/recipe_calculator",
  },
};
